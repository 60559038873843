<!-- 
	This is an empty page for layout demonstrations, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div class="mb-24">
		<div class="bg-white border-gray-3" style="height: calc(100vh - 89px); border: 1px solid">
		</div>
	</div>
</template>

<script>

export default ({
	data() {
		return {
		}
	},
})

</script>

<style lang="scss"></style>